<template>
  <div class="OperationPosForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :footerShow="backData.status !== 0"
      @update="update"
    >
      <col2-detail>
        <col2-block>
          <el-form-item
            label="标题"
            :rules="[
              { required: true, message: '请输入标题', trigger: 'blur' },
            ]"
            prop="title"
          >
            <v-input
              :disabled="onlyLook"
              v-model="form.title"
              :maxlength="10"
              placeholder="标题字数最多10字"
              :width="width"
            />
            &nbsp;&nbsp;&nbsp;
            <v-checkbox
              :disabled="onlyLook"
              class="inlineB"
              v-model="form.appShow"
              type="default"
              label="显示到App上"
            ></v-checkbox>
          </el-form-item>
          <el-form-item label="样式模板" required>
            <style-template
              v-model="form.patternType"
              :disabled="onlyLook"
              @input="changePatternType"
            ></style-template>
          </el-form-item>
        </col2-block>
        <col2-block v-for="(item, index) in form.patternList" :key="index">
          <div class="placeH1">{{ index + 1 }}号位配置</div>
          <el-form-item label="跳转类型" required>
            <checkbox-plus
              :disabled="onlyLook"
              :value.sync="item.skipType"
              :options="skipTypeOpt"
            />
          </el-form-item>
          <el-form-item
            v-if="item.skipType === 2"
            label="外部链接"
            :rules="[
              {
                required: true,
                validator: (rule, value, callback) => {
                  urlValid(rule, item.skipUrl, callback);
                },
                trigger: 'blur',
              },
            ]"
            prop="patternList"
          >
            <v-input
              v-model="item.skipUrl"
              placeholder="外部链接URL地址"
              :width="500"
              :maxlength="200"
              :disabled="onlyLook"
            ></v-input>
            <span class="right-tip"
              >注：请填写以"http://"或"https://"开头的URL地址
            </span>
          </el-form-item>
          <el-form-item
            style="margin: 0"
            v-if="item.skipType === 2"
          ></el-form-item>
          <el-form-item
            v-if="item.skipType === 1"
            label="跳转页面"
            :rules="[
              {
                required: true,
                validator: (rule, value, callback) => {
                  valueValid(rule, item.skipData, callback);
                },
                trigger: 'change',
              },
            ]"
            :prop="`patternList.${index}.skipData`"
          >
            <jump-go
              :btnText="jumpText(item.skipData)"
              :disabled="onlyLook"
              :skipType="2"
              @callback="
                (skipdata, name, orignData) => {
                  jumpFunCallback(skipdata, item, name, index, orignData);
                }
              "
            ></jump-go>
          </el-form-item>

          <el-form-item label="展示形式" required prop="patternList">
            <checkbox-plus
              :disabled="onlyLook"
              :value.sync="item.showType"
              :options="showTypeOptions(item)"
            />
          </el-form-item>
          <!-- <el-form-item v-if="item.showType !== 3" label="图片素材" :rules="[{ required: true, validator: (rule, value, callback) => { imgValid(rule, item.picUrl, callback) }, trigger: 'blur' }]" prop="patternList"> -->
          <el-form-item
            v-if="item.showType !== 3"
            label="图片素材"
            :prop="`patternList.${index}.picUrl`"
            :rules="[
              { required: true, message: '请上传图片', trigger: 'change' },
            ]"
          >
            <v-uploader
              :action="uploadURL"
              :imgUrls.sync="item.picUrl"
              :disabled="onlyLook"
              :fileWidth="uploadRemark[index][item.showType].size[0]"
              :fileHeight="uploadRemark[index][item.showType].size[1]"
              operate="none"
              :imageWH="uploadRemark[index][item.showType].size"
            >
              <template #tip>
                <span>{{ uploadRemark[index][item.showType].tips }}</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item
            v-if="item.showType === 2"
            label="主标题"
            :rules="[
              {
                required: true,
                validator: (rule, value, callback) => {
                  valueValid(rule, item.mainTitle, callback);
                },
                trigger: 'blur',
              },
            ]"
            prop="patternList"
          >
            <v-input
              v-model="item.mainTitle"
              :maxlength="6"
              :disabled="onlyLook"
              placeholder="主标题最多6个字"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            v-if="item.showType === 2"
            label="副标题"
            :rules="[
              {
                required: true,
                validator: (rule, value, callback) => {
                  valueValid(rule, item.subTitle, callback);
                },
                trigger: 'blur',
              },
            ]"
            prop="patternList"
          >
            <v-input
              v-model="item.subTitle"
              :maxlength="14"
              :disabled="onlyLook"
              placeholder="副标题最多14个字"
              :width="width"
            />
          </el-form-item>
        </col2-block>
        <col2-block>
          <el-form-item label="发布位置" required>
            <checkbox-plus
              :value.sync="form.publishPosition"
              :options="publishPositionOpt"
              :disabled="onlyLook"
            />
          </el-form-item>
          <el-form-item
            label="排序"
            :rules="[
              { required: true, message: '请输入排序', trigger: 'blur' },
            ]"
            prop="sort"
          >
            <v-input-number
              :disabled="onlyLook"
              v-model="form.sort"
              placeholder="请输入排序"
              controls
              controlsPosition="right"
              :width="width"
            />
            &nbsp;&nbsp;&nbsp;排序越小越在前，0位于最前
          </el-form-item>
          <el-form-item
            label="组织置顶"
            :rules="[
              { required: true, message: '请选择状态', trigger: 'change' },
            ]"
            prop="orgSticky"
          >
            <v-select
              :disabled="onlyLook"
              class="inlineB"
              v-model="form.orgSticky"
              :options="orgStickyOps"
              :width="width"
            />
            &nbsp;&nbsp;&nbsp;组织置顶之后，该运营位将出现在所辖组织超管、管理员的运营位第一位置
          </el-form-item>
          <el-form-item
            v-if="showLabelSelect"
            label="关联组织标签"
            :rules="[
              {
                required: !isEdit,
                message: '请选择组织标签',
                trigger: 'change',
              },
            ]"
            prop="firstOrgTagList"
          >
            <checkbox-plus
              type="default"
              :options="labelList"
              mode="checkbox-plus"
              :value.sync="form.firstOrgTagList"
              :disabled="isEdit"
            />
          </el-form-item>
          <el-form-item
            v-if="!id"
            label="发布项目"
            :rules="[
              {
                required: true,
                message: '请输入发布项目',
                trigger: 'change',
                type: 'array',
              },
            ]"
            prop="communityIdList"
          >
            <chosenListPanel
              :list.sync="form.communityIdList"
              @select="toSelectCommunity"
            ></chosenListPanel>
          </el-form-item>
          <el-form-item v-else label="发布项目" required>
            {{ backData.communityName }}
          </el-form-item>
          <el-form-item
            label="上线时间"
            :rules="[
              { required: true, message: '请输入上线时间', trigger: 'blur' },
            ]"
            prop="onlineFromDate"
          >
            <v-datepicker
              :disabled="onlyLook"
              :minuteStep="10"
              v-model="form.onlineFromDate"
              type="datetime"
              :minDate="new Date()"
              :maxDate="form.onlineToDate"
            />
            &nbsp;&nbsp;&nbsp;注：时间间隔可设为10分钟
          </el-form-item>
          <el-form-item
            label="下线时间"
            :rules="[
              { required: true, message: '请输入下线时间', trigger: 'blur' },
            ]"
            prop="onlineToDate"
          >
            <v-datepicker
              :disabled="onlyLook"
              :minuteStep="10"
              v-model="form.onlineToDate"
              type="datetime"
              :minDate="form.onlineFromDate || new Date()"
            />
          </el-form-item>
        </col2-block>
        <col2-block title="操作信息" v-if="id">
          <el-form-item
            v-if="backData.status !== 0 && backData.status !== 1"
            label="运营位状态"
            :rules="[
              {
                required: true,
                message: '请选择运营位状态',
                trigger: 'change',
              },
            ]"
            prop="status"
          >
            <v-select
              class="inlineB"
              v-model="form.status"
              :disabled="form.status === 0"
              :options="statusOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item v-else label="运营位状态">
            {{ statusMap[backData.status] }}
          </el-form-item>
          <el-form-item
            v-if="form.status === 3"
            label="退回原因"
            :rules="[
              { required: true, message: '请选择退回原因', trigger: 'blur' },
            ]"
            prop="returnReason"
          >
            <v-textarea
              v-model="form.returnReason"
              :maxlength="200"
            ></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <v-button v-if="!id" slot="saveAfterSlot" @click="toPreview"
        >预览</v-button
      >
    </form-panel>
    <!-- 组织标签 multi-select begin -->
    <multi-select
      title="组织标签列表"
      :isShow.sync="orgTagMultiShow"
      :searchParams="orgTagSearchParams"
      v-bind="orgTagMultiParams"
      :backFill.sync="form.orgTagIdList"
    >
      <template #searchSlot>
        <v-input
          label="标签名称"
          v-model="orgTagSearchParams.tagName"
        ></v-input>
        <v-select2
          label="上级标签"
          v-model="orgTagSearchParams.parentId"
          v-bind="parentOrgParams"
        ></v-select2>
      </template>
    </multi-select>
    <!-- 组织标签 multi-select end -->
    <!-- 发布项目 multi-select begin -->
    <multi-select
      title="项目列表"
      :isShow.sync="communityMultiShow"
      :searchParams="communitySearchParams"
      v-bind="communityMultiParams"
      :backFill.sync="form.communityIdList"
    >
      <template #searchSlot>
        <v-input
          label="项目名称"
          v-model="communitySearchParams.communityName"
        ></v-input>
        <v-select
          label="区域类型"
          v-model="communitySearchParams.searchRegionType"
          :options="regionTypeOps"
        ></v-select>
        <v-select2
          label="所属区域"
          v-model="communitySearchParams.regionId"
          v-bind="allRegionParams"
          :subjoin="{ regionType: communitySearchParams.searchRegionType }"
        ></v-select2>
        <v-input
          label="所在省"
          v-model="communitySearchParams.province"
        ></v-input>
        <v-input
          label="所在城市"
          v-model="communitySearchParams.city"
        ></v-input>
        <v-input label="所在区" v-model="communitySearchParams.area"></v-input>
        <!-- <v-select2 label="运营项目组" v-model="communitySearchParams.operateRegionId" v-bind="operateRegionParams"></v-select2> -->
        <v-select
          label="项目阶段状态"
          v-model="communitySearchParams.communityStage"
          :options="communityStageOps"
        ></v-select>
        <v-select
          label="项目类型"
          v-model="communitySearchParams.communityType"
          :options="communityTypeOps"
        ></v-select>
        <v-select
          label="项目状态"
          v-model="communitySearchParams.communityStatus"
          :options="communityStatusOps"
        ></v-select>
      </template>
    </multi-select>
    <mobile-preview
      :isShow.sync="isPreviewShow"
      title="运营位预览"
      :iframeUrl="mobilePreviewUrl"
    ></mobile-preview>
    <!-- 发布项目 项目 multi-select end -->
  </div>
</template>
<script>
import {
  createURL,
  uploadURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  commonOrgTagSearchURL,
  getSuperiorTagByTagNameURL,
  mobilePreviewURL,
  checkSkipCommunityURL,
  getAllFirstLabel,
} from "./api";
import {
  statusMap,
  setStatusOps,
  setOrgStickyOps,
  initPatternList,
  setSkipTypeOptOps,
  setShowTypeOptOps,
  setPublishPositionOps,
  regionTypeOps,
  communityStatusMap,
  setCommunityStatusOps,
  jumpGoCodeMap,
} from "./map";
import { allRegionParams } from "common/select2Params";
import { urlRegular } from "common/regular";
import { vUploader, vCheckbox } from "components/control";
import {
  Col2Detail,
  Col2Block,
  MultiSelect,
  ChosenListPanel,
  CheckboxPlus,
  JumpGo,
  MobilePreview,
} from "components/bussiness";
import styleTemplate from "./StyleTemplate";
import { sessionStorageHelper } from "common/utils";
import { jumpGoCode } from "components/bussiness/jump-go/code";
export default {
  name: "OperationPosForm",
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    styleTemplate,
    MultiSelect,
    ChosenListPanel,
    CheckboxPlus,
    JumpGo,
    vCheckbox,
    MobilePreview,
  },
  data() {
    return {
      width: 182,
      skipType: 1,
      isEdit: false,
      id: this.$route.query.id,
      uploadURL: uploadURL,
      showLabelSelect: false,
      labelList: [],
      orgStickyOps: setOrgStickyOps(2),
      skipTypeOpt: setSkipTypeOptOps(),
      // showTypeOpt,
      statusMap,
      publishPositionOpt: setPublishPositionOps(),
      form: {
        title: "",
        patternType: 1,
        patternList: initPatternList(3),
        appShow: 0,
        sort: "",
        orgTagIdList: [],
        orgSticky: undefined,
        onlineFromDate: "",
        onlineToDate: "",
        publishPosition: 1,
        communityIdList: [],
        status: 2,
        returnReason: "",
        firstOrgTagList: [],
      },
      submitConfig: {
        queryUrl: `${createURL}/${this.$route.query.id}`,
        submitUrl: "",
        submitMethod: this.$route.query.id ? "put" : "post",
      },
      // 组织标签 multi-select begin
      orgTagMultiShow: false,
      parentOrgParams: {
        searchUrl: getSuperiorTagByTagNameURL,
        request: {
          text: "tagName",
          value: "tagId",
        },
        response: {
          text: "tagName",
          value: "id",
        },
      },
      orgTagSearchParams: {
        tagName: "",
        parentId: "",
      },
      orgTagMultiParams: {
        searchUrl: commonOrgTagSearchURL,
        headers: [
          {
            prop: "tagName",
            label: "标签名称",
          },
          {
            prop: "tagLevel",
            label: "标签层级",
          },
          {
            prop: "parentName",
            label: "上级标签",
          },
          {
            prop: "statusName",
            label: "状态",
          },
        ],
        responseParams: {
          id: "id",
          name: "tagName",
        },
        responseKey: {
          id: "id",
          name: "text",
        },
      },
      // 组织标签 multi-select end
      // 项目 multi-select begin
      communityStatusOps: setCommunityStatusOps(1),
      regionTypeOps,
      allRegionParams,
      communityStageOps: [],
      communityTypeOps: [],
      communityMultiShow: false,
      communitySearchParams: {
        communityName: "",
        searchRegionType: undefined,
        regionId: "",
        province: "",
        city: "",
        area: "",
        operateRegionId: "",
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0,
      },
      operateRegionParams: {
        searchUrl: getOperateRegionsURL,
        request: {
          text: "regionName",
          value: "operateRegionId",
        },
      },
      communityMultiParams: {
        searchUrl: getCommunityListURL,
        headers: [
          {
            prop: "communityName",
            label: "项目名称",
          },
          {
            prop: "communityStatusText",
            label: "项目状态",
          },
          {
            prop: "communityStage",
            label: "项目阶段",
          },
          {
            prop: "provinceName",
            label: "所在省",
          },
          {
            prop: "cityName",
            label: "所在市",
          },
          {
            prop: "countryName",
            label: "所在区",
          },
          {
            prop: "communityPhone",
            label: "项目电话",
          },
        ],
        responseParams: {
          id: "id",
          name: "communityName",
        },
        responseKey: {
          id: "id",
          name: "text",
        },
        handleData(data) {
          data.communityStatusText = communityStatusMap[data.communityStatus];
        },
      },
      // 项目 multi-select end
      isPreviewShow: false,
      mobilePreviewUrl: mobilePreviewURL,
      backData: {},
      onlyLook: false,
    };
  },
  computed: {
    jumpText() {
      return (data) => {
        if (data) {
          return JSON.parse(data).name;
        } else {
          return "点击选择要跳转的页面";
        }
      };
    },
    statusOps() {
      let [first, ...other] = setStatusOps();
      return this.backData.status === 0 ? [first] : other;
    },
    showTypeOptions() {
      return (data) => {
        let arr = [
          "20000",
          "20001",
          "20019",
          "30031",
          "109",
          20000,
          20001,
          20019,
          30031,
          109,
        ];
        let isCustom =
          this.form.patternType === 4 &&
          data.skipType === 1 &&
          data.skipData &&
          arr.indexOf(JSON.parse(data.skipData).pageId) > -1;
        return isCustom
          ? [...setShowTypeOptOps(), { label: "定制", value: 3 }]
          : setShowTypeOptOps();
      };
    },
    uploadRemark(typeIndex) {
      let pIndex = this.form.patternType;
      let remark = [];
      switch (pIndex) {
        case 1:
          remark = [
            [
              null,
              { tips: "注：宽335px高300px，图片小于512KB", size: [335, 300] },
              { tips: "注：宽200px高200px，图片小于512KB", size: [200, 200] },
            ],
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
          ];
          break;
        case 2:
          remark = [
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
            [
              null,
              { tips: "注：宽335px高300px，图片小于512KB", size: [335, 300] },
              { tips: "注：宽200px高200px，图片小于512KB", size: [200, 200] },
            ],
          ];
          break;
        case 3:
          remark = [
            [
              null,
              { tips: "注：宽335px高300px，图片小于512KB", size: [335, 300] },
              { tips: "注：宽200px高200px，图片小于512KB", size: [200, 200] },
            ],
            [
              null,
              { tips: "注：宽335px高300px，图片小于512KB", size: [335, 300] },
              { tips: "注：宽200px高200px，图片小于512KB", size: [200, 200] },
            ],
          ];
          break;
        case 4:
          remark = [
            [
              null,
              { tips: "注：宽690px高294px，图片小于512KB", size: [690, 294] },
              { tips: "注：宽240px高240px，图片小于512KB", size: [240, 240] },
            ],
          ];
          break;
        case 5:
          remark = [
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
          ];
          break;
        case 6:
          remark = [
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
            [
              null,
              { tips: "注：宽335px高140px，图片小于512KB", size: [335, 140] },
              { tips: "注：宽110px高110px，图片小于512KB", size: [110, 110] },
            ],
          ];
          break;
        case 7:
          remark = [
            [
              null,
              { tips: "注：宽343px高548px，图片小于512KB", size: [343, 548] },
              { tips: "注：宽343px高343px，图片小于512KB", size: [343, 343] },
            ],
          ];
          break;
      }

      return remark;
    },
  },
  mounted() {
    const { id } = this.$route.query;
    if (id !== undefined) {
      // this.isEdit = true
      this.$setBreadcrumb("编辑");
      this.submitConfig.submitUrl = `${createURL}/${id}`;
      this.$refs.formPanel.getData({});
    } else {
      this.isEdit = false;
      this.$setBreadcrumb("新增");
      this.submitConfig.submitUrl = createURL;
      this.getProjectTypes();
      this.getRoomTypes();
      // this.getSuperOrgTagList()
    }
    this.getFirstLabel();
  },
  watch: {
    labelList(val) {
      this.showLabelSelect = val.length > 1;
    },
  },
  methods: {
    getProjectTypes() {
      this.$axios.get(getProjectTypesURL).then((res) => {
        if (res.status === "100") {
          let { data } = res;
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id,
            };
          });
          this.communityStageOps = [
            {
              text: "全部",
              value: undefined,
            },
            ...ops,
          ];
        }
      });
    },
    getRoomTypes() {
      this.$axios.get(getRoomTypesURL).then((res) => {
        if (res.status === "100") {
          let { data } = res;
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id,
            };
          });
          this.communityTypeOps = [
            {
              text: "全部",
              value: undefined,
            },
            ...ops,
          ];
        }
      });
    },
    getSuperOrgTagList() {
      let _this = this;
      let superParams = { tagName: "企业" };
      let parentId;
      this.$axios
        .get(getSuperiorTagByTagNameURL, { params: superParams })
        .then((res) => {
          if (res.status === 100) {
            if (res.data.length) {
              parentId = res.data[0].id;
              _this.setOrgTagId(parentId);
            }
          }
        });
    },
    setOrgTagId(parentId) {
      let orgSearchParams = {
        parentId: parentId,
        executeSearch: 1,
        curPage: 1,
        pageSize: 20,
      };
      let _this = this;
      this.$axios
        .get(commonOrgTagSearchURL, { params: orgSearchParams })
        .then((res) => {
          if (res.status === 100) {
            let list = res.data.resultList;
            if (Array.isArray(list) && list) {
              if (!this.form.orgTagIdList.length) {
                _this.form.orgTagIdList = list;
              }
            }
          }
        });
    },
    getFirstLabel() {
      let _this = this;
      this.$axios.get(`${getAllFirstLabel}?isPartyTag=${0}`).then((res) => {
        if (res.status === 100) {
          res.data.forEach(function (item) {
            _this.labelList.push({
              label: item.tagName,
              value: item.id,
            });
          });
        }
      });
    },
    update(data) {
      this.backData = data;
      this.form = {
        title: data.title,
        patternType: data.patternType,
        patternList: data.patternVList,
        appShow: data.appShow === 1,
        sort: data.sort,
        orgTagIdList: data.orgTagList.map((item) => {
          return {
            text: item.orgTagName,
            id: item.id,
          };
        }),
        orgSticky: data.orgSticky,
        onlineFromDate: data.onlineFromDate,
        onlineToDate: data.onlineToDate,
        publishPosition: data.publishPosition,
        status: data.status,
        communityIdList: [],
        returnReason: data.returnReason,
        firstOrgTagList: data.firstOrgTagList,
      };
      // this.getSuperOrgTagList()
      this.isEdit = data.status !== 0; // 在待审核、已审核和已退回状态下除了发布项目和组织标签外都可以修改
      this.onlyLook = data.status === 0; // 在关闭状态下所有的字段只读
    },
    // 校验跳转内容是否覆盖项目
    async checkSkipCommunity(data) {
      let params = {
        communityList: [],
        storeIdList: [],
        socialActivityIdList: [],
        secKillIdList: [],
        knowledgeInfoIdList: [],
        discountTopicId: [],
        groupIdList: [],
        productIdList: [],
        questionIdList: [],
        questionNaireIdList: [],
        selfRuleIdList: [],
        subIdList: [],
        tableIdList: [],
        workRoomIdList: [],
      };
      params.communityList = data.communityIdList.map((item) => {
        return {
          communityId: item.id,
          communityName: item.text,
        };
      });
      data.patternList.forEach((item) => {
        if (item.skipType === 1) {
          let skipData = JSON.parse(item.skipData);
          let isAdd = false;
          Object.keys(jumpGoCode).forEach((items) => {
            if (skipData.pageId === jumpGoCode[items]) {
              isAdd = true;
              params[jumpGoCodeMap[items]].push(skipData.detailId);
            }
          });
          // 问卷调查特殊处理=>至详情的问卷调查才能跳转到问卷调查详情页
          if (skipData.pageId === "40000") {
            isAdd = true;
            params["questionIdList"].push(skipData.detailId);
          }
          !isAdd && params.subIdList.push(skipData.detailId);
        }
      });
      let res = await this.$axios.post(checkSkipCommunityURL, params);
      if (res.status === 100) {
        if (res.data && res.data.length > 0) {
          let tempList = [];
          res.data.forEach((item, index) => {
            tempList.push(item.communityName);
          });
          let message = `跳转内容无法覆盖项目【${tempList.join(
            "、"
          )}】发布之后这些项目点击将无法实现跳转，请确认是否发布`;
          let isOk = await this.$confirm(message, { title: "提示" });
          if (!isOk) {
            // 不同意，就要清空所选的园区
            this.form.communityIdList = [];
            return false;
          }
          return isOk;
        } else {
          return true;
        }
      }
    },
    async submitBefore(data) {
      if (
        this.backData.status === 1 &&
        !(await this.$confirm(
          "该运营位内容已通过审核，若进行修改会影响用户查看到的内容，是否确认修改?"
        ))
      ) {
        return;
      }
      let patternList = [];
      data.orgTagIdList = data.orgTagIdList.map((item) => item.id);
      if (this.id) {
        delete data.communityIdList;
      } else {
        if (!(await this.checkSkipCommunity(data))) {
          return false;
        }
        data.communityIdList = data.communityIdList.map((item) => item.id);
      }
      data.appShow = data.appShow ? 1 : 0;
      data.patternList.forEach((item) => {
        let pat = {
          pattern: item.pattern,
          showType: item.showType,
          skipType: item.skipType,
          picUrl: item.picUrl,
          orignData: item.orignData,
        };
        if (item.skipType === 1) {
          pat.skipData = item.skipData;
        } else {
          pat.skipUrl = item.skipUrl;
        }
        if (item.showType === 2) {
          pat.mainTitle = item.mainTitle;
          pat.subTitle = item.subTitle;
        }
        if (item.showType === 3) {
          delete pat.picUrl;
        }
        patternList.push(pat);
      });
      if (data.status !== 3) {
        delete data.returnReason;
      }
      data.patternList = patternList;
      return true;
    },
    jumpFunCallback(skipdata, item, name, index, orignData) {
      if (!name) return;
      item.orignData = orignData;
      item.skipData = JSON.stringify(skipdata);
      this.$refs.formPanel.validateField(
        `patternList.${index}.skipData`,
        (errorMessage) => {}
      );
    },
    toSelectCommunity() {
      this.communityMultiShow = true;
    },
    toSelectOrgTag() {
      this.orgTagMultiShow = true;
    },
    // url校验
    urlValid(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入跳转地址"));
      } else if (urlRegular.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的跳转地址"));
      }
    },
    valueValid(rule, value, callback) {
      if (!value) {
        callback(new Error("此项不能为空"));
      } else {
        callback();
      }
    },
    imgValid(rule, value, callback) {
      if (!value) {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    },
    async toPreview() {
      let result = await this.$refs.formPanel.validate();
      if (!result) {
        return;
      }
      // 处理预览数据
      let patternVList = [];
      let templateData = this.form.patternList;
      for (let i = 0; i < templateData.length; i++) {
        let item = templateData[i];
        let index = i;
        let skipModel = {};
        skipModel.pattern = index + 1;
        skipModel.showType = item.showType;
        skipModel.skipType = item.skipType;
        let skipData = item.skipData;
        let previewData = item.orignData;
        // 至功能页面
        if (skipModel.skipType === 1) {
          if (!skipData) {
            return false;
          }
          skipModel.skipData = skipData;
        } else if (skipModel.skipType === 2) {
          // 至外部链接
          skipModel.skipUrl = item.skipUrl;
        }
        if (skipModel.showType === 1) {
          // 封面图
          skipModel.picUrl = item.picUrl;
        } else if (skipModel.showType === 2) {
          // icon
          skipModel.picUrl = item.picUrl;
          skipModel.mainTitle = item.mainTitle;
          skipModel.subTitle = item.subTitle;
        } else {
          // 定制
          skipModel.skipData = skipData;
          let customObj = {
            activityObj: {},
            goodsObj: {},
            questionObj: {},
            secKillObj: {},
            storeObj: {},
          };
          skipData = JSON.parse(skipData);
          let pageId = skipData.pageId;
          switch (pageId) {
            case "30031": // 活动
              customObj.activityObj = {
                activityName: skipData.name,
                activityPicture: previewData.activityPicture,
                activityTimeTag: previewData.activityTimeTag,
                id: skipData.detailId,
              };
              break;
            case "20001": // 商品
              customObj.goodsObj = {
                id: skipData.detailId,
                goodsName: skipData.name,
                goodsPrice: previewData.productPrice,
                sold: previewData.sold,
                goodsPic: previewData.productImg,
              };
              break;
            case "109": // 调查问卷
              customObj.questionObj = {
                title: skipData.title,
                joinUserCnt: previewData.joinUserCnt,
                publishTimeTag: previewData.publishTimeTag,
                id: skipData.detailId,
              };
              break;
            case "20019": // 秒杀
              if (previewData.productList && previewData.productList.length) {
                previewData.productList.forEach(function (item) {
                  item.productPic = item.image;
                  item.productName = item.name;
                  if (item.killPrice.toString().indexOf(".") > -1) {
                    item.price = item.killPrice.toString().split(".")[0];
                    item.price_dec = item.killPrice.toString().split(".")[1];
                  } else {
                    item.price = item.killPrice;
                    item.price_dec = "00";
                  }
                });
              }
              customObj.secKillObj = {
                status: previewData.status,
                countTime: previewData.countTime,
                productList: previewData.productList,
                id: skipData.detailId,
              };
              break;
            case "20000": // 门店
              customObj.storeObj = {
                id: skipData.detailId,
                storeName: skipData.name,
                storeNotice: previewData.storeNotice,
                storeLogo: previewData.storeLogo,
                overallScore: previewData.overallScore,
              };
              break;
          }
          skipModel.customObj = customObj;
        }
        patternVList.push(skipModel);
      }
      let data = {
        title: this.form.title,
        patternVList: patternVList,
        appShow: this.form.appShow ? 1 : 0,
        patternType: this.form.patternType,
      };
      sessionStorageHelper.setItem("preview-list", [data]);
      this.isPreviewShow = true;
    },
    changePatternType(val) {
      if (!this.onlyLook) {
        if (val === 1 || val === 2) {
          this.form.patternList = initPatternList(3);
        } else if (val === 3 || val === 5) {
          this.form.patternList = initPatternList(2);
        } else if (val === 6) {
          this.form.patternList = initPatternList(4);
        } else {
          this.form.patternList = initPatternList(1);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.inlineB {
  display: inline-block;
}
.placeH1 {
  font-size: 25px;
  padding-left: 40px;
  margin: 10px 0;
}
</style>
