var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OperationPosForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              footerShow: _vm.backData.status !== 0,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题",
                        rules: [
                          {
                            required: true,
                            message: "请输入标题",
                            trigger: "blur",
                          },
                        ],
                        prop: "title",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          disabled: _vm.onlyLook,
                          maxlength: 10,
                          placeholder: "标题字数最多10字",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                      _vm._v("\n             \n          "),
                      _c("v-checkbox", {
                        staticClass: "inlineB",
                        attrs: {
                          disabled: _vm.onlyLook,
                          type: "default",
                          label: "显示到App上",
                        },
                        model: {
                          value: _vm.form.appShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appShow", $$v)
                          },
                          expression: "form.appShow",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "样式模板", required: "" } },
                    [
                      _c("style-template", {
                        attrs: { disabled: _vm.onlyLook },
                        on: { input: _vm.changePatternType },
                        model: {
                          value: _vm.form.patternType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "patternType", $$v)
                          },
                          expression: "form.patternType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.form.patternList, function (item, index) {
                return _c(
                  "col2-block",
                  { key: index },
                  [
                    _c("div", { staticClass: "placeH1" }, [
                      _vm._v(_vm._s(index + 1) + "号位配置"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "跳转类型", required: "" } },
                      [
                        _c("checkbox-plus", {
                          attrs: {
                            disabled: _vm.onlyLook,
                            value: item.skipType,
                            options: _vm.skipTypeOpt,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(item, "skipType", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    item.skipType === 2
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "外部链接",
                              rules: [
                                {
                                  required: true,
                                  validator: (rule, value, callback) => {
                                    _vm.urlValid(rule, item.skipUrl, callback)
                                  },
                                  trigger: "blur",
                                },
                              ],
                              prop: "patternList",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                placeholder: "外部链接URL地址",
                                width: 500,
                                maxlength: 200,
                                disabled: _vm.onlyLook,
                              },
                              model: {
                                value: item.skipUrl,
                                callback: function ($$v) {
                                  _vm.$set(item, "skipUrl", $$v)
                                },
                                expression: "item.skipUrl",
                              },
                            }),
                            _c("span", { staticClass: "right-tip" }, [
                              _vm._v(
                                '注：请填写以"http://"或"https://"开头的URL地址\n          '
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.skipType === 2
                      ? _c("el-form-item", { staticStyle: { margin: "0" } })
                      : _vm._e(),
                    item.skipType === 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "跳转页面",
                              rules: [
                                {
                                  required: true,
                                  validator: (rule, value, callback) => {
                                    _vm.valueValid(
                                      rule,
                                      item.skipData,
                                      callback
                                    )
                                  },
                                  trigger: "change",
                                },
                              ],
                              prop: `patternList.${index}.skipData`,
                            },
                          },
                          [
                            _c("jump-go", {
                              attrs: {
                                btnText: _vm.jumpText(item.skipData),
                                disabled: _vm.onlyLook,
                                skipType: 2,
                              },
                              on: {
                                callback: (skipdata, name, orignData) => {
                                  _vm.jumpFunCallback(
                                    skipdata,
                                    item,
                                    name,
                                    index,
                                    orignData
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "展示形式",
                          required: "",
                          prop: "patternList",
                        },
                      },
                      [
                        _c("checkbox-plus", {
                          attrs: {
                            disabled: _vm.onlyLook,
                            value: item.showType,
                            options: _vm.showTypeOptions(item),
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(item, "showType", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    item.showType !== 3
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "图片素材",
                              prop: `patternList.${index}.picUrl`,
                              rules: [
                                {
                                  required: true,
                                  message: "请上传图片",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-uploader", {
                              attrs: {
                                action: _vm.uploadURL,
                                imgUrls: item.picUrl,
                                disabled: _vm.onlyLook,
                                fileWidth:
                                  _vm.uploadRemark[index][item.showType]
                                    .size[0],
                                fileHeight:
                                  _vm.uploadRemark[index][item.showType]
                                    .size[1],
                                operate: "none",
                                imageWH:
                                  _vm.uploadRemark[index][item.showType].size,
                              },
                              on: {
                                "update:imgUrls": function ($event) {
                                  return _vm.$set(item, "picUrl", $event)
                                },
                                "update:img-urls": function ($event) {
                                  return _vm.$set(item, "picUrl", $event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "tip",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.uploadRemark[index][
                                                item.showType
                                              ].tips
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.showType === 2
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "主标题",
                              rules: [
                                {
                                  required: true,
                                  validator: (rule, value, callback) => {
                                    _vm.valueValid(
                                      rule,
                                      item.mainTitle,
                                      callback
                                    )
                                  },
                                  trigger: "blur",
                                },
                              ],
                              prop: "patternList",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                maxlength: 6,
                                disabled: _vm.onlyLook,
                                placeholder: "主标题最多6个字",
                                width: _vm.width,
                              },
                              model: {
                                value: item.mainTitle,
                                callback: function ($$v) {
                                  _vm.$set(item, "mainTitle", $$v)
                                },
                                expression: "item.mainTitle",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.showType === 2
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "副标题",
                              rules: [
                                {
                                  required: true,
                                  validator: (rule, value, callback) => {
                                    _vm.valueValid(
                                      rule,
                                      item.subTitle,
                                      callback
                                    )
                                  },
                                  trigger: "blur",
                                },
                              ],
                              prop: "patternList",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                maxlength: 14,
                                disabled: _vm.onlyLook,
                                placeholder: "副标题最多14个字",
                                width: _vm.width,
                              },
                              model: {
                                value: item.subTitle,
                                callback: function ($$v) {
                                  _vm.$set(item, "subTitle", $$v)
                                },
                                expression: "item.subTitle",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布位置", required: "" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          value: _vm.form.publishPosition,
                          options: _vm.publishPositionOpt,
                          disabled: _vm.onlyLook,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "publishPosition", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "排序",
                        rules: [
                          {
                            required: true,
                            message: "请输入排序",
                            trigger: "blur",
                          },
                        ],
                        prop: "sort",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          disabled: _vm.onlyLook,
                          placeholder: "请输入排序",
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                      _vm._v(
                        "\n             排序越小越在前，0位于最前\n        "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "组织置顶",
                        rules: [
                          {
                            required: true,
                            message: "请选择状态",
                            trigger: "change",
                          },
                        ],
                        prop: "orgSticky",
                      },
                    },
                    [
                      _c("v-select", {
                        staticClass: "inlineB",
                        attrs: {
                          disabled: _vm.onlyLook,
                          options: _vm.orgStickyOps,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.orgSticky,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "orgSticky", $$v)
                          },
                          expression: "form.orgSticky",
                        },
                      }),
                      _vm._v(
                        "\n             组织置顶之后，该运营位将出现在所辖组织超管、管理员的运营位第一位置\n        "
                      ),
                    ],
                    1
                  ),
                  _vm.showLabelSelect
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联组织标签",
                            rules: [
                              {
                                required: !_vm.isEdit,
                                message: "请选择组织标签",
                                trigger: "change",
                              },
                            ],
                            prop: "firstOrgTagList",
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "default",
                              options: _vm.labelList,
                              mode: "checkbox-plus",
                              value: _vm.form.firstOrgTagList,
                              disabled: _vm.isEdit,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "firstOrgTagList",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.id
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发布项目",
                            rules: [
                              {
                                required: true,
                                message: "请输入发布项目",
                                trigger: "change",
                                type: "array",
                              },
                            ],
                            prop: "communityIdList",
                          },
                        },
                        [
                          _c("chosenListPanel", {
                            attrs: { list: _vm.form.communityIdList },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "communityIdList",
                                  $event
                                )
                              },
                              select: _vm.toSelectCommunity,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "发布项目", required: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backData.communityName) +
                              "\n        "
                          ),
                        ]
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上线时间",
                        rules: [
                          {
                            required: true,
                            message: "请输入上线时间",
                            trigger: "blur",
                          },
                        ],
                        prop: "onlineFromDate",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          disabled: _vm.onlyLook,
                          minuteStep: 10,
                          type: "datetime",
                          minDate: new Date(),
                          maxDate: _vm.form.onlineToDate,
                        },
                        model: {
                          value: _vm.form.onlineFromDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "onlineFromDate", $$v)
                          },
                          expression: "form.onlineFromDate",
                        },
                      }),
                      _vm._v(
                        "\n             注：时间间隔可设为10分钟\n        "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "下线时间",
                        rules: [
                          {
                            required: true,
                            message: "请输入下线时间",
                            trigger: "blur",
                          },
                        ],
                        prop: "onlineToDate",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          disabled: _vm.onlyLook,
                          minuteStep: 10,
                          type: "datetime",
                          minDate: _vm.form.onlineFromDate || new Date(),
                        },
                        model: {
                          value: _vm.form.onlineToDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "onlineToDate", $$v)
                          },
                          expression: "form.onlineToDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.id
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作信息" } },
                    [
                      _vm.backData.status !== 0 && _vm.backData.status !== 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "运营位状态",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择运营位状态",
                                    trigger: "change",
                                  },
                                ],
                                prop: "status",
                              },
                            },
                            [
                              _c("v-select", {
                                staticClass: "inlineB",
                                attrs: {
                                  disabled: _vm.form.status === 0,
                                  options: _vm.statusOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "运营位状态" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.statusMap[_vm.backData.status]) +
                                  "\n        "
                              ),
                            ]
                          ),
                      _vm.form.status === 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退回原因",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择退回原因",
                                    trigger: "blur",
                                  },
                                ],
                                prop: "returnReason",
                              },
                            },
                            [
                              _c("v-textarea", {
                                attrs: { maxlength: 200 },
                                model: {
                                  value: _vm.form.returnReason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "returnReason", $$v)
                                  },
                                  expression: "form.returnReason",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          !_vm.id
            ? _c(
                "v-button",
                {
                  attrs: { slot: "saveAfterSlot" },
                  on: { click: _vm.toPreview },
                  slot: "saveAfterSlot",
                },
                [_vm._v("预览")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "multi-select",
        _vm._b(
          {
            attrs: {
              title: "组织标签列表",
              isShow: _vm.orgTagMultiShow,
              searchParams: _vm.orgTagSearchParams,
              backFill: _vm.form.orgTagIdList,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.orgTagMultiShow = $event
              },
              "update:is-show": function ($event) {
                _vm.orgTagMultiShow = $event
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "orgTagIdList", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "orgTagIdList", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "标签名称" },
                      model: {
                        value: _vm.orgTagSearchParams.tagName,
                        callback: function ($$v) {
                          _vm.$set(_vm.orgTagSearchParams, "tagName", $$v)
                        },
                        expression: "orgTagSearchParams.tagName",
                      },
                    }),
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "上级标签" },
                          model: {
                            value: _vm.orgTagSearchParams.parentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.orgTagSearchParams, "parentId", $$v)
                            },
                            expression: "orgTagSearchParams.parentId",
                          },
                        },
                        "v-select2",
                        _vm.parentOrgParams,
                        false
                      )
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multi-select",
          _vm.orgTagMultiParams,
          false
        )
      ),
      _c(
        "multi-select",
        _vm._b(
          {
            attrs: {
              title: "项目列表",
              isShow: _vm.communityMultiShow,
              searchParams: _vm.communitySearchParams,
              backFill: _vm.form.communityIdList,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.communityMultiShow = $event
              },
              "update:is-show": function ($event) {
                _vm.communityMultiShow = $event
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "communityIdList", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "communityIdList", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "项目名称" },
                      model: {
                        value: _vm.communitySearchParams.communityName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityName",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityName",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "区域类型", options: _vm.regionTypeOps },
                      model: {
                        value: _vm.communitySearchParams.searchRegionType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "searchRegionType",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.searchRegionType",
                      },
                    }),
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: {
                            label: "所属区域",
                            subjoin: {
                              regionType:
                                _vm.communitySearchParams.searchRegionType,
                            },
                          },
                          model: {
                            value: _vm.communitySearchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "regionId",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.allRegionParams,
                        false
                      )
                    ),
                    _c("v-input", {
                      attrs: { label: "所在省" },
                      model: {
                        value: _vm.communitySearchParams.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.communitySearchParams, "province", $$v)
                        },
                        expression: "communitySearchParams.province",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在城市" },
                      model: {
                        value: _vm.communitySearchParams.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.communitySearchParams, "city", $$v)
                        },
                        expression: "communitySearchParams.city",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在区" },
                      model: {
                        value: _vm.communitySearchParams.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.communitySearchParams, "area", $$v)
                        },
                        expression: "communitySearchParams.area",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目阶段状态",
                        options: _vm.communityStageOps,
                      },
                      model: {
                        value: _vm.communitySearchParams.communityStage,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityStage",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityStage",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目类型",
                        options: _vm.communityTypeOps,
                      },
                      model: {
                        value: _vm.communitySearchParams.communityType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityType",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityType",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目状态",
                        options: _vm.communityStatusOps,
                      },
                      model: {
                        value: _vm.communitySearchParams.communityStatus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityStatus",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityStatus",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multi-select",
          _vm.communityMultiParams,
          false
        )
      ),
      _c("mobile-preview", {
        attrs: {
          isShow: _vm.isPreviewShow,
          title: "运营位预览",
          iframeUrl: _vm.mobilePreviewUrl,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isPreviewShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isPreviewShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }