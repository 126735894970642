var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "style-template-container" }, [
    _c(
      "div",
      {
        staticClass: "out-wrapper",
        class: { active: _vm.value === 1 },
        on: {
          click: function ($event) {
            return _vm.blockClick(1)
          },
        },
      },
      [_c("div", { staticClass: "col block" }, [_vm._v("一号位")]), _vm._m(0)]
    ),
    _c(
      "div",
      {
        staticClass: "out-wrapper",
        class: { active: _vm.value === 2 },
        on: {
          click: function ($event) {
            return _vm.blockClick(2)
          },
        },
      },
      [_vm._m(1), _c("div", { staticClass: "col block" }, [_vm._v("三号位")])]
    ),
    _c(
      "div",
      {
        staticClass: "out-wrapper",
        class: { active: _vm.value === 3 },
        on: {
          click: function ($event) {
            return _vm.blockClick(3)
          },
        },
      },
      [
        _c("div", { staticClass: "col block" }, [_vm._v("一号位")]),
        _c("div", { staticClass: "col block" }, [_vm._v("二号位")]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "out-wrapper",
        class: { active: _vm.value === 4 },
        on: {
          click: function ($event) {
            return _vm.blockClick(4)
          },
        },
      },
      [_c("div", { staticClass: "block widthFull" }, [_vm._v("一号位")])]
    ),
    _c(
      "div",
      {
        staticClass: "out-wrapper-te",
        class: { active: _vm.value === 7 },
        on: {
          click: function ($event) {
            return _vm.blockClick(7)
          },
        },
      },
      [_vm._m(2)]
    ),
    _c("div", { staticClass: "out-wrapper-last" }, [
      _c(
        "div",
        {
          staticClass: "out-wrapper",
          class: { active: _vm.value === 5 },
          on: {
            click: function ($event) {
              return _vm.blockClick(5)
            },
          },
        },
        [_vm._m(3), _vm._m(4)]
      ),
      _c(
        "div",
        {
          staticClass: "out-wrapper",
          class: { active: _vm.value === 6 },
          on: {
            click: function ($event) {
              return _vm.blockClick(6)
            },
          },
        },
        [_vm._m(5), _vm._m(6)]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "row block" }, [_vm._v("二号位")]),
      _c("div", { staticClass: "row block" }, [_vm._v("三号位")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "row block" }, [_vm._v("一号位")]),
      _c("div", { staticClass: "row block" }, [_vm._v("二号位")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "row block" }, [
        _vm._v("一号位（电子钱包专用）"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "row block" }, [_vm._v("一号位")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "row block" }, [_vm._v("二号位")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "row block" }, [_vm._v("一号位")]),
      _c("div", { staticClass: "row block" }, [_vm._v("二号位")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "row block" }, [_vm._v("三号位")]),
      _c("div", { staticClass: "row block" }, [_vm._v("四号位")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }