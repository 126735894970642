<template>
  <div class="style-template-container">
    <div class="out-wrapper" :class="{ active: value === 1}" @click="blockClick(1)">
      <div class="col block">一号位</div>
      <div class="col">
        <div class="row block">二号位</div>
        <div class="row block">三号位</div>
      </div>
    </div>
    <div class="out-wrapper" :class="{ active: value === 2}" @click="blockClick(2)">
      <div class="col">
        <div class="row block">一号位</div>
        <div class="row block">二号位</div>
      </div>
      <div class="col block">三号位</div>
    </div>
    <div class="out-wrapper" :class="{ active: value === 3}" @click="blockClick(3)">
      <div class="col block">一号位</div>
      <div class="col block">二号位</div>
    </div>
    <div class="out-wrapper" :class="{ active: value === 4}" @click="blockClick(4)">
      <div class="block widthFull">一号位</div>
    </div>
    
    
    <div class="out-wrapper-te" :class="{ active: value === 7}" @click="blockClick(7)">
      <div class="col">
        <div class="row block">一号位（电子钱包专用）</div>
      </div>
    </div>
    <div class="out-wrapper-last">
      <div class="out-wrapper" :class="{ active: value === 5}" @click="blockClick(5)">
      <div class="col">
        <div class="row block">一号位</div>
      </div>
      <div class="col">
        <div class="row block">二号位</div>
      </div>
    </div>
    <div class="out-wrapper" :class="{ active: value === 6}" @click="blockClick(6)">
      <div class="col">
        <div class="row block">一号位</div>
        <div class="row block">二号位</div>
      </div>
      <div class="col">
        <div class="row block">三号位</div>
        <div class="row block">四号位</div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'style-template',
  props: {
    value: {
      required: true,
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    blockClick (val) {
      if (this.disabled) {
        return false
      }
      this.$emit('input', val)
    }
  }
}
</script>
<style lang="scss" scoped>
  .style-template-container{
    display: flex;
    min-width: 680px;
    width: 680px;
    flex-wrap: wrap;
    .out-wrapper {
      box-sizing: border-box;
      width: 340px;
      height: 156px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      border: 3px solid #fff;
      cursor: pointer;
      .col {
        width: 157px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .row {
          height: 65px;
        }
      }
      .widthFull {
        width: 100%;
      }
      .block {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #aaa;
      }
    }
    .out-wrapper-te {
      box-sizing: border-box;
      width: 340px;
      height: 310px;
      padding: 5px;
      border: 3px solid #fff;
      cursor: pointer;
      .col {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .row {
          height: 100%;
        }
      }
      .block {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #aaa;
      }
    }
    .out-wrapper-last {
      box-sizing: border-box;
      width: 320px;
      height: 340px;
      
    }
    .active {
      border-color: #1B8CF2;
    }
  }
</style>
